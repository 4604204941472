import Plyr from "plyr";
import gsap from 'gsap';
import {
    App,
    H
} from ".";
import Util from "./util";

export default class AppHandler {
    constructor(player) {
        this.splashscreen = document.getElementById("transition__Wrapper");
        this.html = document.getElementsByTagName('html')[0];
        this.menulinks = document.querySelectorAll('nav a');
        this.cpI_Open = "inset(0px 0px 0px 0px)";
        this.cpI_Top = "inset(0px 0px 100% 0px)";
        this.cpI_Bottom = "inset(100% 0px 0px 0px)";
        this.transWrapDuration = .3;
        this.podcastActive = null;
        this.transWrapEasing = "power3.out";
        this.radio = player;
        this.radio.on('ended', (event) => {
            App.nextSong();
        });

        if (process.env.NODE_ENV === 'development') {
            this.envDir = "";
        } else {
            this.envDir = "/dist";
        }

        this.progressiveEnhancementJS();
        this.isHomePage();
    }
    // Add js class to html
    progressiveEnhancementJS() {
        Util.toggleClass(this.html, 'js', true);

    }

    isHomePage() {
        // Get the current browser url.
        const url = window.location.pathname;
        console.log(url);
        // Selecting the body tag
        const body = document.getElementsByTagName("BODY")[0];
        // Adding a custom check to check whether the url contains our domain.
           
        
        if (url == '/') {
            body.classList.add('home');
            body.classList.remove('others');
          } else {
            body.classList.add('others');
            body.classList.remove('home');

          }
    }

    // Hide splashscreen
    splashInit() {
        gsap.fromTo(
            this.splashscreen, {
                clipPath: this.cpI_Open
            }, {
                clipPath: this.cpI_Top,
                duration: this.transWrapDuration,
                ease: "ease",
                delay: 0.6,
                onComplete: function () {
                    var wrapper = document.getElementById('transition__Wrapper');
                    wrapper.classList.add("hide");
                }
            }
        );
    }
    // Show splashscreen
    splashLoad() {
        var wrapper = document.getElementById('transition__Wrapper');
        wrapper.classList.remove("hide");
        gsap.fromTo(
            this.splashscreen, {
                clipPath: this.cpI_Bottom
            }, {
                clipPath: this.cpI_Open,
                duration: this.transWrapDuration,
                ease: "ease",
                delay: 0.25
            });
    }

    // Keep active class on menu updated
    menuUpdater() {
        for (let i = 0; i < this.menulinks.length; i++) {
            const link = this.menulinks[i];
            var mainHeader = document.getElementsByClassName('js-header');
            // Clean class
            link.removeAttribute("aria-current");
            if (mainHeader.length > 0) {
                var trigger = mainHeader[0].getElementsByClassName('js-header__trigger')[0],
                    nav = mainHeader[0].getElementsByClassName('js-header__nav')[0];
                Util.toggleClass(nav, 'header__nav--is-visible', false);
                Util.toggleClass(mainHeader[0], 'header--expanded', false);
                trigger.setAttribute('aria-expanded', false);
            }
            // Active link
            if (link.href === location.href) {
                link.setAttribute("aria-current", "");
            }
        }
    }


    // PLAYLISTER
    getId(el) {
        return Number(el.getAttribute('data-id'));
    }

    buildSource(el) {
        var obj = [{
            src: el.getAttribute('data-audio'),
            type: 'audio/mp3'
        }];
        return obj;
    }

    changePodcast(el) {
        this.setSource(this.getId(el), this.buildSource(el), true);
        this.setInfos(this.getId(el));
        gsap.to(el, {
            opacity: 0,
            duration: 0.2,
            ease: "power4.out",
            onComplete: function () {
                el.remove();
            }
        })
    }

    setSource(selected, sourceAudio, play) {
        if (this.podcastActive !== selected) {
            this.podcastActive = selected;
            this.radio.source = {
                type: 'audio',
                title: 'Les podcasts du musée',
                sources: sourceAudio
            };
            if (play) {
                this.radio.play();
            }
        } else {
            this.radio.togglePlay();
        }
    }

    nextSong(e) {
        var next = this.podcastActive + 2;
        var tg = document.getElementById("nextpodcast");
        tg.innerHTML = '';

        if (next === 10) {
            var thanksNotice = document.createElement('div');
            thanksNotice.setAttribute('class', 'podcast__link podcast__link--thanks');
            thanksNotice.innerHTML = 'Vous avez tout écouté !';
            document.querySelector('body').classList.add("next-proposed");
            tg.innerHTML = '';
            tg.appendChild(thanksNotice);

            return
        }
        var newlink = document.createElement('a');
        newlink.innerHTML = 'Podcast suivant';
        newlink.innerHTML += '<svg style="width:1em;height:1em" viewBox="0 0 24 24"> <path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" /> </svg>'
        newlink.setAttribute('title', 'Podcast suivant');
        newlink.setAttribute('href', '/podcast-' + next + '.html');
        newlink.setAttribute('class', 'podcast__link text-decoration-none');
        document.querySelector('body').classList.add("next-proposed");

        H.attach([newlink]);
        tg.appendChild(newlink);
    }

    setInfos(id) {
        var target = document.getElementById("playlister");
        var title = target.querySelector(".currentTitle");
        var header = document.getElementById("podcast-" + id);
        var selectedTitle = header.querySelector("h2").innerHTML.replace(/\<br\>/g, " ");
        var color = header.parentNode.style.cssText;
        var arrStr = color.split(/[:;]/)[1];

        var newlink = document.createElement('a');
        newlink.innerHTML = selectedTitle;
        newlink.setAttribute('title', 'Titre du podcast actuel');
        newlink.setAttribute('href', '/podcast-' + (id + 1) + '.html');
        newlink.setAttribute('class', 'podcast__link text-decoration-none');

        title.innerHTML = ""
        H.attach([newlink]);
        title.appendChild(newlink)
        target.style.setProperty('--color', arrStr);
    }


}