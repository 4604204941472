import Highway from "@dogstudio/highway";
import Overpass from "./transitions";
import Plyr from "plyr";
import { controls } from "./plyrSettings";
import AppHandler from "./AppHandler";
import PodcastRenderer from "./podcastRenderer";
import { PodcastRendererMutli, AboutRenderer } from "./podcastRenderer";


// CONFIGURATION
const H = new Highway.Core({
  renderers: {
    'home': PodcastRendererMutli,
    'podcast': PodcastRenderer,
    'about': AboutRenderer,
    'default': PodcastRenderer
  },
  transitions: { default: Overpass }
});

const player = new Plyr("#playlister audio", {
  controls,
  toggleInvert: 'false',
  storage: { enabled: false, key: 'plyr' }, 
});


// INIT
const App = new AppHandler(player);
export { App, H };

// App.menuUpdater();
App.splashInit();


// GENERAL BEHAVIOUR
H.on('NAVIGATE_IN', ({ to, location}) => {
  // App.menuUpdater();
  App.isHomePage();

});


function getAbsoluteHeight(el) {
  // Get the DOM Node if you pass in a string
  el = (typeof el === 'string') ? document.querySelector(el) : el; 

  var styles = window.getComputedStyle(el);
  var margin = parseFloat(styles['marginTop']) +
               parseFloat(styles['marginBottom']);

  return Math.ceil(el.offsetHeight + margin);
}

// VH 
const rootVars = () => {
	const vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty("--vh", `${vh}px`);

  const tH = getAbsoluteHeight(document.querySelector(".currentTitle"));
  const pH = getAbsoluteHeight(document.querySelector(".plyr "));

  const playerHeight = tH + pH;

	document.documentElement.style.setProperty("--player-h", `${playerHeight}px`);

};
window.addEventListener("resize", rootVars);
rootVars();
