import Highway from '@dogstudio/highway';
import { App } from ".";

export default class PodcastRenderer extends Highway.Renderer {
    constructor(properties) {
        super(properties);
    }
    onEnter() {
        document.getElementById("nextpodcast").innerHTML = "";
        document.querySelector('body').classList.remove("next-proposed");

    }
    
    onLeave() {}
    
    onEnterCompleted() {
        const button = document.getElementById("playpod");
        const target = button.querySelector('button');
        target.addEventListener("click", (e) => {
            e.preventDefault();
            App.changePodcast(e.target);
            document.getElementById("playlister").classList.add("playing");
            document.querySelector('body').classList.add("plyr--playing");
        });
    }
    onLeaveCompleted() {}
}

export class PodcastRendererMutli extends Highway.Renderer {
    constructor(properties) {
        super(properties);
    }
    onEnter() {}
    onLeave() {}
    onEnterCompleted() {}
    onLeaveCompleted() {}
}

export class AboutRenderer extends Highway.Renderer {
    constructor(properties) {
        super(properties);
    }
    onEnter() {}
    onLeave() {}
    onEnterCompleted() {}
    onLeaveCompleted() {}
}